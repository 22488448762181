import React from "react"

function CareerTitle(){
    return(
        <div className="careerTitle">
            <img src="https://i.imgur.com/6EvjcMd.png" alt="© DreamCraft Entertainment"/>
            <h2>Join us to empower massive creative talents!</h2>
        </div>
    )
}

export default CareerTitle