import React, { useState } from 'react'
import { Link, graphql } from "gatsby"
import CareerTitle from "../components/careers/CareerTitle"
import Nav from "../components/Nav"
import Footer from '../components/Footer'
import { useSpring, animated } from 'react-spring'
import SEO from '../components/SEO'
import './job-post.css'

function JobTemplate({data}) {
    const { edges: jobs } = data.allMarkdownRemark;
    const { markdownRemark: job } = data;
    const [sidebarOn, setSidebar] = useState(false)
    const responsiveSetSidebar = () =>{
        if (typeof window !== `undefined`) {
            if (window.innerWidth < 770)
                setSidebar(!sidebarOn)
        }
    }
    const opacity = useSpring({ from: {x: 0, transform: "translate3d(0, 40px, 0)"}, to: {x: 1, transform: "translate3d(0, 0, 0)"}, delay: 250})
    return (
        <>
            <SEO title={job.frontmatter.title} description={job.frontmatter.description} keywords={job.frontmatter.keywords} />
            <Nav sidebarOn={sidebarOn} setSidebar={responsiveSetSidebar} active={'career'}/>
            <div className={`App ${sidebarOn ? "open" : "closed"}`} onClick={() => { if (sidebarOn) setSidebar(); }}>
                <main>
                    <section className="careers">
                        <CareerTitle />
                        <div className="jobsWrapper">
                            <div className="jobs">
                                {
                                    jobs.map(
                                        ({node: job}) => 
                                        <Link key={job.frontmatter.path} activeClassName="current" to={job.frontmatter.path}>
                                            {job.frontmatter.title}
                                        </Link>
                                    )
                                }
                            </div>
                            <div className="jobDescription">
                                <animated.div style={{...opacity, 
                                    opacity: opacity.x.interpolate({
                                    range: [0.0, 0.2, 0.95, 1],
                                    output: [0, 1, 1, 1]
                                })}}
                                className="hiringText">
                                    <div className="hiringText">
                                        {job.frontmatter.path.indexOf("2201") == -1 &&
                                        (
                                            <>
                                                <h1>{job.frontmatter.title}</h1>
                                                <h3 className="sponsor">Please send your resume to <a href="mailto:jobs@dreamcraft.com">jobs@dreamcraft.com</a></h3>
                                                
                                            </>
                                        )}
                                        <div dangerouslySetInnerHTML={{ __html: job.html }} />
                                        {job.frontmatter.path.indexOf("2201") == -1 && job.frontmatter.sponsor && <h3 className="sponsor">We sponsor CPT/OPT/H1B for international students.</h3>}
                                    </div>
                                </animated.div>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
        </>
    );
}

export default JobTemplate;
export const pageQuery = graphql`
  query JobPostByPath($path: String!) {
    allMarkdownRemark(filter: {
        fileAbsolutePath: {
           regex: "/(jobs)/"
        }
     }, sort: { order: ASC, fields: [frontmatter___order] }) {
      edges {
        node {
          frontmatter {
            title
            path
          }
        }
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      excerpt(pruneLength: 250)
      html
      frontmatter {
        title
        path
        type
        description
        keywords
        applylink
        sponsor
      }
    }
  }
`